/**
 *
 * @Container props.data
 * className: css classes for parent container
 * data{
 * type:"container/full", (full will stretch 100% while container will wrap banner to occupy width of content)
 * img,
 * backgroundSize,(css property to apply depending if will be cover or centered image for use of type= container)
 * backgroundPosition : oposite of blurb position moves image to oposite side
 * title,
 * blurb,
 * blurbPosition (class to align text: left , right ,center)
 * flexDirection left/right will flip direction of flex columns
 * buttonText,
 * buttonLink }
 */

import { Link } from "react-router-dom";

const Banner = (props) => {
  var parse = require("html-react-parser");

  const {
    type,
    img,
    imgClassname,
    title,
    blurb,
    buttonText,
    buttonLink,
    textClassname,
    backgroundSize,
    blurbPosition,
    backgroundPosition,
  } = props.data;
  const flexDirection =
    blurbPosition === "left"
      ? "banner-card d-lg-flex  flex-row d-md-inline-flex my-4 "
      : "banner-card d-lg-flex  flex-row-reverse d-md-inline-flex my-4";
  return type === "container" ? (
    <div
      // style={{
      //   background: `url(${img})`,
      //   backgroundSize: ` ${backgroundSize}`,
      //   backgroundPosition: `${
      //     backgroundPosition ? backgroundPosition : "center"
      //   }`,
      // }}
      className={` ${flexDirection} ${
        props?.className ? props.className : ""
      } ${imgClassname ? imgClassname : ""}`}
    >
      {/* {backgroundPosition === "left" && (
        <img src={img} className="mw-100 w-100 pe-4" />
      )} */}
      <div
        className={`content  ${textClassname ? textClassname : ""} ${
          blurbPosition === "left" ? " px-4  " : "px-4"
        } `}
      >
        {title && <h1>{title}</h1>}
        {blurb && <div className="banner-blurb">{parse(blurb)}</div>}
        {buttonText && (
          <Link
            className="btn btn-primary btn-lg mb-4 banner-button"
            to={buttonLink}
            role="button"
          >
            {buttonText}
          </Link>
        )}
      </div>
      {img && <img src={img} className="mw-100 mb-2 rounded-3" />}
    </div>
  ) : (
    <div
      style={{
        background: `url(${img})`,
        backgroundSize: ` ${backgroundSize}`,
        backgroundPosition: `center`,
      }}
      className={`banner ${props?.className ? props.className : ""} ${
        imgClassname ? imgClassname : ""
      }`}
    >
      <div className={`banner-content ${textClassname ? textClassname : ""}`}>
        {title && <h3>{title}</h3>}
        {blurb && <span className="banner-blurb">{blurb}</span>}
        {buttonText && (
          <Link
            className="btn btn-primary btn-lg mb-4 banner-button"
            to={buttonLink}
            role="button"
          >
            {buttonText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Banner;

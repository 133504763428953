import { useSelector } from "react-redux";
import BlogListCard from "./BlogListCard";

const PromotionList = (props) => {
  const promotions = useSelector((state) => state.promotion.promotions);
  return (
    <div className="promotions">
      {promotions?.length > 0 &&
        promotions?.map((promo, idx) => (
          <BlogListCard key={idx} data={promo} />
        ))}
    </div>
  );
};
export default PromotionList;

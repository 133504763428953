/**
 *  @file config.js
 *  @brief Brief
 */

class Config {
  //process.env.NODE_ENV // this is a process to pick from node so we can pass environment variable to application // if we need to  TBD

  constructor() {
    // this.project = "retail"; // finance/retail/automoto/whatever.../
    this.project = "fengshui"; //as default will load
    this.default = `/json/${this.project}/site.config.json`;
  }
}
export default new Config();

/**
 *
 * @Container props.data
 * className: css classes for parent container
 * data{
 * type:"container/full", (full will stretch 100% while container will wrap banner to occupy width of content)
 * img,
 * backgroundSize,(css property to apply depending if will be cover or centered image for use of type= container)
 * backgroundPosition : oposite of blurb position moves image to oposite side
 * title,
 * blurb,
 * blurbPosition (class to align text: left , right ,center)
 * buttonText,
 * buttonLink }
 */

import { Link } from "react-router-dom";

const Banner = (props) => {
  var parse = require("html-react-parser");
  const {
    type,
    img,
    imgClassname,
    title,
    blurb,
    buttonText,
    buttonLink,
    textClassname,
    backgroundSize,
    blurbPosition,
    backgroundPosition,
  } = props.data;
  return type === "container" ? (
    <div
      style={{
        background: `url(${img})`,
        backgroundSize: ` ${backgroundSize}`,
        backgroundPosition: `${
          backgroundPosition ? backgroundPosition : "center"
        }`,
      }}
      className={`banner container ${props?.className ? props.className : ""} ${
        imgClassname ? imgClassname : ""
      }`}
    >
      <div
        className={`banner-content ${textClassname ? textClassname : ""} ${
          blurbPosition ? blurbPosition : ""
        } `}
      >
        {title && <h3>{title}</h3>}
        {blurb && <div className="banner-blurb">{parse(blurb)}</div>}
        {buttonText && (
          <Link
            className="btn btn-primary btn-lg mb-4 banner-button"
            to={buttonLink}
            role="button"
          >
            {buttonText}
          </Link>
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        background: `url(${img})`,
        backgroundSize: ` ${backgroundSize}`,
        backgroundPosition: `center`,
      }}
      className={`banner ${props?.className ? props.className : ""} ${
        imgClassname ? imgClassname : ""
      }`}
    >
      <div className={`banner-content ${textClassname ? textClassname : ""}`}>
        {title && <h3>{title}</h3>}
        {blurb && <span className="banner-blurb">{blurb}</span>}
        {buttonText && (
          <Link
            className="btn btn-primary btn-lg mb-4 banner-button"
            to={buttonLink}
            role="button"
          >
            {buttonText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Banner;

import { useSelector } from "react-redux";
import CardContainer from "./Common/cardContainer";

/**
 *
 * @param {*} props
 * title
 * nullCheckMsg
 *
 */

const Wishlist = (props) => {
  var title, nullCheckMsg;
  if (props?.data) {
    var { title, nullCheckMsg } = props.data;
  }
  const products = useSelector((state) => state.product.products);
  var wishlistItems = products?.filter((prod) => prod.inWishlist);
  wishlistItems = wishlistItems.map((itm) => {
    return { id: itm.id };
  });
  if (title && nullCheckMsg && wishlistItems?.length === 0) {
    var cardContainerData = [
      {
        title,
        componentContent: nullCheckMsg,
      },
    ];
  } else {
    var cardContainerData = [
      {
        title,
        catalogCard: wishlistItems,
      },
    ];
  }

  return <CardContainer data={cardContainerData} />;
};

export default Wishlist;

import { Helmet } from "react-helmet";

const Metatags = (props) => {
   const { data } = props;
  
    if (data) {
       console.log("HELMET", data);
    return (
      <Helmet>
        <meta charSet="utf-8" />
        {data.websiteName ? <title>{data.websiteName}</title> : null}
        {data.items?.name
                ? data.items.name.map((meta, index) => {
                    return Object.entries(meta).map(([key, val]) => (
                      <meta key={index} name={key} content={val} />
                    ));
            })
          : null}
        {data.items?.property
          ? data.items.property.map((meta, index) => {
             return Object.entries(meta).map(([key, val]) => (
               <meta key={index} property={key} content={val} />
             ));
            })
          : null}
        {data.items?.httpequiv
          ? data.items.httpequiv.map((meta, index) => {
               return Object.entries(meta).map(([key, val]) => (
                 <meta key={index} http-equiv={key} content={val} />
               ));
            })
          : null}
      </Helmet>
    );
  }
  return <div></div>;
};

export default Metatags;
// {
//     data.items.lenght > 0 ? (
//         data.items.map((meta, index) => {
//             meta.map((item, index) => {
//                 console.log("HELMET", item);
//             }
//             )
//         })
//     ):null
// }    
    // data.items.lenght > 0 ? (
    //     data.items.map((item, index) => (
    //         item.name?.lenght > 0 ? (
    //             item.name.map((l, idx) =>
    //             ()
    //             )
    //         ) : null;
                        
            // item.property?.lenght > 0 ? (      
            //         item.property.map((l, idx) =>
            //             Object.entries(l).map(([key, val]) => (
            //             <meta key={idx} property={key} content={val} />
            //             ))
            //         );
            //  ) : null            
                     
    //                 item.httpequiv.map((l, idx) =>
    //                     Object.entries(l).map(([key, val]) => (
    //                     <meta key={idx} http-equiv={key} content={val} />
    //                     ))
    //                 );
    //             ))
    //         )}   
           
        
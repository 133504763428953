import { Collapse } from "react-collapse";
import { useState } from "react";
/*
@Component props "contentArray" array with  :
    title
    content 
*/
var parse = require("html-react-parser");
const ContentContainer = (props) => {
  const { data } = props;
  const [activeIndex, setActiveIndex]  = useState(0);
  const toggleClass = (e) => {
    if (activeIndex === e) { console.log("====", activeIndex,e);}
    else {setActiveIndex(e); console.log("!=", activeIndex, e);}
  };

  const moreLess = (index) => {
    if (activeIndex === index) {
      return (
        <span className="link">
           {/* Less<i className="fas fa-angle-up" /> */}
        </span>
      )
    } else {
      return (
        <span className="link pointer">
          mehr
          <i className="fas fa-angle-down m-2" />
        </span>
      );
    }
  };

  return (
    <div className="row py-3 pb-4 mb-4 content-component">
      {data.map((item, inx) => (
        <div className="block item pb-4 mb-4" key={inx}>
          {item.title && <h2>{item.title}</h2>}
          {item.teaser && parse(item.teaser)}
          {item.content && (
            <div className={activeIndex == inx ? "show" : "hide"}>
              <Collapse isOpened={activeIndex == inx}>
                {parse(item.content)}
              </Collapse>
            </div>
          )}
          <span className="more" onClick={() => toggleClass(inx)}>
            {moreLess(inx)}
          </span>
        </div>
      ))}
    </div>
  );
};
export default ContentContainer;

import React, { useEffect } from "react";
import Navigation from "../Common/navigation";
/**
 *
 * @Components props.data
 * name: component name (not user for rendering)
 * notes : copyright info,
 * img: company logo,
 * items[]: footerlinks contains array of {url, name}
 */

const Footer = (props) => {
  useEffect(() => {
    
  });
    
  const { data, site } = props;

 

  return (
    <div
      className="footer row pt-3 mx-auto center"
      style={{
        backgroundColor: props?.data?.backgroundColor
          ? props.data.backgroundColor
          : "#fff",
      }}
    >
      <div className="col-sm-12 font-0_7">
        {data && data.notes}
      </div>
      {/* <div className="col-lg-4 col-sm-12 col-md-5">
        <img src={data && data.img} alt="" className="hgs-logo" />
      </div>
      <div className="col-lg-4 col-sm-12 col-md-4 m-auto font-0_7 ">
        {
          data && data.navigations && (
            // data.navigations.map((nav, idx) => (
            <Navigation data={data.navigations} />
          )
          // ))
        }
      </div> */}
    </div>
  );
};
export default Footer;

import { Link } from "react-router-dom";

/**
 *
 * @Container props.data{
 * title
 * content
 * details: object of key value pairs to be displayed
 * }
 *
 */
var parse = require("html-react-parser");
const ContactDetail = (props) => {
  const { title, content, details } = props.data;

  return (
    <div className="contact-details pb-5">
      <h2 className="contact-title ">{title}</h2>
      <span className="py-3 d-block">{parse(content)}</span>
      {details &&
        Object.entries(details).map(([key, value], idx) => {
          return (
            <span key={idx} className="d-block text-capitalize">
              {key} : <Link to="/contact">{value}</Link>
            </span>
          );
        })}
    </div>
  );
};

export default ContactDetail;

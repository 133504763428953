import axios from "axios";
import { useEffect, useState } from "react";
import Metatags from "../components/core/metatags";
import AccountCard from "../components/AccountCard";
import ActivityCard from "../components/ActivityCard";
import Banner from "../components/Common/Banner";
import BannerCard from "../components/Common/BannerCard";
import ContactCard from "../components/Common/contactCard";
import ContactDetail from "../components/ContactDetails";
import Links from "../components/Common/linksContainer";
import LoginForm from "../components/Common/loginForm";
import MortgageCalc from "../components/MortgageCalc";
import ThreeColCards from "../components/ThreeColCards";
import FourColCards from "../components/Common/FourColCards";
import Config from "../config";
import CardContainer from "../components/Common/cardContainer";
import ApplicationProcess from "../components/ApplicationProcess";
import SingleCard from "../components/Common/card.single";
import FormContainer from "../components/Common/formContainer";
import Auth from "../components/login/auth";
import Navigation from "../components/Common/navigation";
import ProductDetails from "../components/ProductDetail";
import { getProducts } from "../store/products-actions";
import { useDispatch } from "react-redux";
import SimpleModal from "../components/Common/modal";
import Embed from "../components/Common/embed";
import EmbedFB from "../components/Common/embed-fb";
import PhotoGallery from "../components/Common/photoGallery";
import ContentContainer from "../components/Common/contentContainer.js";
import ListContainer from "../components/Common/listContainer";
import Cart from "../components/Cart";
import CheckOut from "../components/Checkout";
import { getBlogs } from "../store/blogs-actions";
import BlogArticle from "../components/BlogArticle";
import BlogCategories from "../components/BlogCategories";
import Wishlist from "../components/Wishlist";
import ContentComponent from "../components/Common/ContentComponent";
import { getpromotions } from "../store/promotions-actions";
import PromotionList from "../components/PromotionList";

const componentsMap = {
  banner: Banner,
  bannerCard: BannerCard,
  contactCard: ContactCard,
  contactInfoSection: ContactDetail,
  cards: ThreeColCards,
  card: SingleCard,
  headerCard: SingleCard,
  links: Links,
  fourcolcards: FourColCards,
  accountCard: AccountCard,
  activityCard: ActivityCard,
  mortgageCalculator: MortgageCalc,
  cardContainer: CardContainer,
  applicationProcess: ApplicationProcess,
  formContainer: FormContainer,
  navigations: Navigation,
  productDetail: ProductDetails,
  cart: Cart,
  checkout: CheckOut,
  simpleModal: SimpleModal,
  embed: Embed,
  embedFB: EmbedFB,
  listContainer: ListContainer,
  gallery: PhotoGallery,
  contentContainer: ContentContainer,
  metatags: Metatags,
  blogArticle: BlogArticle,
  blogCategories: BlogCategories,
  wishlist: Wishlist,
  contentComponent: ContentComponent,
  promotionList: PromotionList,
};

const HomePage = (props) => {
  const path = Config.default.slice(0, Config.default.lastIndexOf("/"));
  const [pageData, setPageData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchJson = async () => {
      try {
        const res = await axios.get(path + props.jsonFile);
        if (res.data) {
          setPageData(res.data);
        }
      } catch (e) {}
    };
    if (props.jsonFile) fetchJson();
  }, [props.jsonFile, path]);
  useEffect(() => {
    dispatch(getpromotions());
    dispatch(getProducts());
    dispatch(getBlogs());
  }, [dispatch]);

  if (pageData) {
    var {
      banner,
      login,
      navigations,
      components,
      pagename,
      pagetype,
      col1class,
      col2class,
      pagetitle,
      headerCard,
      site,
    } = pageData;
  }
  if (pagetype) {
    var pageRenderFormat = pagetype.split("-");
  }
  if (navigations) {
    var leftnav = navigations.filter((nav) => nav.position === "left");
    var rightnav = navigations.filter((nav) => nav.position === "right");
    var topnav = navigations.filter((nav) => nav.position === "top");
    var bottomnav = navigations.filter((nav) => nav.position === "bottom");
    var leftnavcol, rightnavcol, centerCol;
    if (leftnav.length && rightnav.length) {
      leftnavcol = "col-2";
      rightnavcol = "col-2";
      centerCol = "col-8";
    } else if (leftnav.length) {
      leftnavcol = "col-2";
      centerCol = "col-10";
    } else if (rightnav.length) {
      rightnavcol = "col-2";
      centerCol = "col-10";
    }
  }
  const auth = Auth();
  return (
    <div className={`main ${pagename}`}>
      {/* {login && pagename === "home" && !auth && (
        <LoginForm className="display-form" />
      )} */}
      {login && pagename === "login" && !auth && (
        <LoginForm className="login-page-form" />
      )}
      {banner ? (
        <Banner
          data={banner}
          className={pagename === "home" ? "h-30" : "h-20"}
        />
      ) : null}
      <div className="container">
        {pagetitle || headerCard ? (
          <div className="page-head">
            {pagetitle && <h1 className="py-4">{pagetitle}</h1>}
            {headerCard && <SingleCard data={headerCard} />}
          </div>
        ) : null}
        {topnav && (
          <div className="">
            <Navigation data={topnav} />
          </div>
        )}
        <div
          style={
            pageRenderFormat &&
            pageRenderFormat.length > 1 &&
            pageRenderFormat[1] === "reverse"
              ? { flexDirection: "row-reverse" }
              : { flexDirection: "row" }
          }
        >
          {leftnav && (
            <div className={leftnavcol}>
              <Navigation data={leftnav} />
            </div>
          )}
          <div className={` ${centerCol ? centerCol : ""}`}>
            <div
              className={
                pageRenderFormat && pageRenderFormat[0] === "full"
                  ? ``
                  : col1class
                  ? col1class
                  : `col-md-7 col-12`
              }
            >
              {components &&
                components.col1 &&
                components.col1.map((item, idx) =>
                  Object.entries(item).map(function ([key, val]) {
                    const Comp = componentsMap[key];
                    return (
                      <Comp
                        data={val}
                        site={site}
                        key={idx}
                        componentsMap={componentsMap}
                      />
                    );
                  })
                )}
            </div>
            <div className={col2class ? col2class : "col-md-5 col-12"}>
              {components &&
                components.col2 &&
                components.col2.map((item, idx) =>
                  Object.entries(item).map(function ([key, val]) {
                    const Comp = componentsMap[key];
                    return <Comp data={val} site={site} key={idx} />;
                  })
                )}
            </div>
          </div>
          {rightnav && (
            <div className={rightnavcol}>
              <Navigation data={rightnav} />
            </div>
          )}
        </div>
        {bottomnav && (
          <div className="">
            <Navigation data={bottomnav} />
          </div>
        )}
      </div>
    </div>
  );
};
export default HomePage;

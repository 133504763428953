import { Table } from "react-bootstrap";
import User from "../login/userData";

const ListContainer = (props) => {
  if (props.data) {
    var { list, isKeyVal, title, userData, cart } = props.data;
  }
  const user = User();
  if (!list && userData) {
    list = [user];
  }
  if (isKeyVal) {
    return (
      <div className="py-5 border-bottom">
        {title && <div className="title">{title}</div>}
        <Table borderless>
          <tbody>
            {list &&
              list.map((l, idx) =>
                Object.entries(l).map(([key, val]) => (
                  <tr key={idx}>
                    <td className="text-capitalize">{key}</td>
                    <td>{val}</td>
                  </tr>
                ))
              )}
          </tbody>
        </Table>
      </div>
    );
  }
  return <div></div>;
};

export default ListContainer;

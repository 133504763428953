import { createSlice } from "@reduxjs/toolkit";

const promotionsSlice = createSlice({
  name: "promotion",
  initialState: {
    promotions: [],
  },
  reducers: {
    replacePromotions(state, action) {
      state.promotions = action.payload;
    },
  },
});

export const promotionActions = promotionsSlice.actions;
export default promotionsSlice.reducer;

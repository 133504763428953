import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ThreeColCards = (props) => {
  return (
    <div className="row py-3">
      {props.data.map((card, idx) => (
        <Card key={idx} className="col-lg-4 col-12 px-0 m-2 cards_style">
          {card.img && <Card.Img variant="top" src={card.img} />}
          <Card.Body>
            <Card.Title>{card.title}</Card.Title>
            <Card.Text>{card.content}</Card.Text>

            {card.buttonLink ? (
              <Link to={card.buttonLink}>
                <Button variant="primary" className="my-2">
                  {card.buttonText}
                </Button>
              </Link>
            ) : (
              card.buttonText && (
                <Button variant="primary" className="my-2">
                  {card.buttonText}
                </Button>
              )
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default ThreeColCards;

import { configureStore } from "@reduxjs/toolkit";
import blogsSlice from "./blogs-slice";
import cartSlice from "./cart-slice";
import myAccountSlice from "./my-account-slice";
import productsSlice from "./products-slice";
import promotionsSlice from "./promotions-slice";
const store = configureStore({
  reducer: {
    myAccount: myAccountSlice,
    product: productsSlice,
    cart: cartSlice,
    blog: blogsSlice,
    promotion: promotionsSlice,
  },
});

export default store;

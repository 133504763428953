import axios from "axios";
import config from "../config";
import { productActions } from "./products-slice";
export const getProducts = () => {
  return (dispatch) => {
    const myaccountURL = `/json/${config.project}/response/products.json`;
    axios
      .get(myaccountURL)
      .then((res) => {
        dispatch(productActions.replaceProducts(res.data));
      })
      .catch((err) => console.log(err));
  };
};

export const addToWishlist = (item) => {
  return (dispatch) => {
    dispatch(productActions.addToWishlist(item));
  };
};

export const removeFromWishlist = (item) => {
  return (dispatch) => {
    dispatch(productActions.removeFromWishlist(item));
  };
};

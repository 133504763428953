import { useSelector } from "react-redux";
function Auth() {
  const isLoggedIn = useSelector((state) => state.myAccount.isLoggedIn);
  console.log("isAuthenticated", isLoggedIn);
  const isAuthenticated = () => {
    //return isLoggedIn;
    return true
  };
  return isAuthenticated();
  
}

export default Auth;

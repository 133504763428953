import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * @Components props.data
 * title: Card Title
  upcomingpayment: upcoming payment amount
  buttonText
  buttonLink,
  paymentDueDate,
  lastPaymentReceived,
  autopay,
 */

const AccountCard = (props) => {
  if (props?.data) {
    var {
      title,
      upcomingpayment,
      buttonText,
      buttonLink,
      paymentDueDate,
      lastPaymentReceived,
      autopay,
    } = props.data;
  }
  return (
    <div className="card border-0 shadow p-3 mb-3">
      {title && (
        <div className="px-2 pb-3">
          <span className="title">{title}</span>
        </div>
      )}
      <div className="row border bg-grey mx-2 p-2">
        <div className="col-md-7 col-6 font-1_4">
          Upcoming Payment
          <br />
          <b>{upcomingpayment}</b>
        </div>
        <div className="col-md-5 col-6 text-end mt-2">
          {buttonText && (
            <Link to={buttonLink ? buttonLink : "/"}>
              <Button variant="primary">{buttonText}</Button>
            </Link>
          )}
        </div>
        <div className="col-12">
          <div className="row my-4 mx-2 account-details">
            <div className="col-md-4 col-12">
              Payment Due Date: <br />
              {paymentDueDate && <b>{paymentDueDate}</b>}
            </div>
            <div className="col-md-4 col-12">
              Last Payment Received: <br />{" "}
              {lastPaymentReceived && <b>{lastPaymentReceived}</b>}
            </div>
            <div className="col-md-4 col-12">
              Autopay {autopay}
              <br />
              <Link to="/accounts">EDIT</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountCard;

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Accordion, Badge, Breadcrumb, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormContainer from "./Common/formContainer";

const CheckOut = (props) => {
  const items = useSelector((state) => state.cart.items);
  const total = useSelector((state) => state.cart.total);
  const orderSummary = (
    <div>
      <div>
        {items?.map((itm, idx) => (
          <div key={idx} className="row pt-4">
            <div className="col-3 pe-0">
              <img src={itm.img} className="border" width="45" />
              <Badge className="bg-primary checkout-item-badge">
                {itm.quantity}
              </Badge>
            </div>
            <div className="col-6">{itm.name}</div>
            <div className="col-2">${itm.price}</div>
          </div>
        ))}
      </div>
      <hr />
      <Form className="d-flex">
        <Form.Group className="me-2" controlId="discountCode">
          <Form.Control type="text" placeholder="Discount Code" />
        </Form.Group>
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
      <hr />
      <div>
        <div className="d-flex justify-content-between">
          <div>Subtotal</div>
          <div>${total}</div>
        </div>

        <div className="d-flex justify-content-between">
          <div>Discount</div>
          <div>- $0.00</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Shipping</div>
          <div className="shipping">calculated at next step</div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between py-3">
        <div>Total</div>
        <div>
          <span>USD</span>
          <span className="total">${total}</span>
        </div>
      </div>
    </div>
  );
  return (
    <div className="row checkout">
      <div className="col-md-7 col-12">
        <Breadcrumb className="py-3">
          <Breadcrumb.Item>
            <Link to="/cart">Cart</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Information</Breadcrumb.Item>
          <Breadcrumb.Item active>Shipping</Breadcrumb.Item>
          <Breadcrumb.Item active>Payment</Breadcrumb.Item>
        </Breadcrumb>
        <Accordion defaultActiveKey="0" className="pb-4 d-md-none d-sm-block">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="bg-grey">
              Order Summary
            </Accordion.Header>
            <Accordion.Body className="cart-details">
              {orderSummary}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="express_buttons pb-3">
          <hr />
          <span>Express Checkout</span>
          <hr />
          <div className="p-3">
            <PayPalScriptProvider options={{ "client-id": "test" }}>
              <PayPalButtons style={{ layout: "horizontal" }} />
            </PayPalScriptProvider>
          </div>
        </div>
        <div className="Or_checkout">
          <hr />
          <span>Or</span>
          <hr />
        </div>
        <FormContainer data={props.data.formGroups} />
      </div>
      <div className="col-md-5 d-sm-none d-md-block cart-details">
        {orderSummary}
      </div>
    </div>
  );
};

export default CheckOut;

import axios from "axios";
import config from "../config";
import { promotionActions } from "./promotions-slice";
export const getpromotions = () => {
  return (dispatch) => {
    const myaccountURL = `/json/${config.project}/response/promotions.json`;
    axios
      .get(myaccountURL)
      .then((res) => {
        dispatch(promotionActions.replacePromotions(res.data));
      })
      .catch((err) => console.log(err));
  };
};

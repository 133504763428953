import { createSlice, current } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalItems: 0,
    total: 0,
  },
  reducers: {
    addAnItem(state, action) {
      var updatedItems = [];
      var item = { ...action.payload };
      const itemIndex = state.items.findIndex((itm) => itm.id === item.id);
      const updatedTotal = +Number(
        Number(state.total) + (item.isSale ? item.salePrice : item.price)
      ).toFixed(2);
      const updatedItemCount = state.totalItems + 1;
      if (itemIndex < 0) {
        item.quantity = 1;
        item.amount = item.isSale ? item.salePrice : item.price;
        updatedItems = state.items.concat(item);
      } else {
        const updated = {
          ...state.items[itemIndex],
          quantity: state.items[itemIndex].quantity + 1,
          amount: +Number(
            state.items[itemIndex].amount +
              (item.isSale ? item.salePrice : item.price)
          ).toFixed(2),
        };
        updatedItems = [...state.items];
        updatedItems[itemIndex] = updated;
      }
      state.items = updatedItems;
      state.total = updatedTotal;
      state.totalItems = updatedItemCount;
    },
    removeAnItem(state, action) {
      var updatedItems = [];
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload
      );
      const item = state.items[itemIndex];
      let updatedTotal = +Number(
        Number(state.total) - (item.isSale ? item.salePrice : item.price)
      ).toFixed(2);
      const updatedItemCount = state.totalItems - 1;
      if (item.quantity > 1) {
        let updatedItem = {
          ...state.items[itemIndex],
          quantity: state.items[itemIndex].quantity - 1,
          amount: +Number(
            state.items[itemIndex].amount -
              (item.isSale ? item.salePrice : item.price)
          ).toFixed(2),
        };
        updatedItems = [...state.items];
        updatedItems[itemIndex] = updatedItem;
      } else {
        updatedItems = state.items.filter((item) => item.id !== action.payload);
      }
      state.items = updatedItems;
      state.total = updatedTotal;
      state.totalItems = updatedItemCount;
    },
    removeItem(state, action) {
      var updatedItems = [];
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload
      );
      const item = state.items[itemIndex];
      let updatedTotal = +Number(
        state.total -
          item.quantity * (item.isSale ? item.salePrice : item.price)
      ).toFixed(2);
      const updatedItemCount = state.items - item.quantity;

      updatedItems = state.items.filter((item) => item.id !== action.payload);

      state.items = updatedItems;
      state.total = updatedTotal;
      state.totalItems = updatedItemCount;
    },
    clearCart(state) {
      state.items = [];
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;

import { Row } from "react-bootstrap";
import CatalogItemCard from "../CatalogItemCard";
import CardSingle from "./card.single";
/**
 *  @Component props.data:
 * title
 * componentContent
 * cards[]
 */
const cardContainer = (props) => {
  return props.data.map((cardset, index) => (
    <Row key={index} className="mb-4 mt-3 component card-container">
      {cardset.title && <h2>{cardset.title}</h2>}
      {cardset.componentContent && (
        <div className="mt-2 mb-2">{cardset.componentContent}</div>
      )}
      {cardset.cards &&
        cardset.cards.map((card, idx) => (
          <CardSingle
            className="col-xs-12 col-lg-4 col-sm-6 single-card"
            data={card}
            key={idx}
          />
        ))}
      {cardset.catalogCard &&
        cardset.catalogCard.map((card, idx) => (
          <CatalogItemCard
            key={idx}
            data={card}
            className="col-xs-12 col-lg-3 col-sm-6 mt-2"
          />
        ))}
    </Row>
  ));
};

export default cardContainer;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAccountData, login } from "../../store/my-account-actions";
const LoginForm = (props) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const loginClick = (e) => {
    toast.success(`${username} is logged in`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(login());
    dispatch(getAccountData(username));
  };
  return (
    <form className={`login ${props?.className ? props.className : ""}`}>
      <span className="title">Welcome</span>
      <input
        type="text"
        className="form-control my-2"
        id="userName"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        aria-describedby="userHelp"
        placeholder="Username"
      />
      <input
        type="password"
        className="form-control mb-2"
        id="exampleInputPassword1"
        placeholder="Password"
      />
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="exampleCheck1"
        />
        <label className="form-check-label">Remember me</label>
      </div>
      <Link to="/accounts">
        <button
          type="button"
          onClick={loginClick}
          className="btn btn-primary mt-2 login-button"
        >
          Sign in
        </button>
      </Link>
      <div className="login-links mt-1">
        <Link to="/home">Forgot ID/Password? »</Link>
        <br />
        <Link to="/createAccount">Open an Account »</Link>
      </div>
    </form>
  );
};
export default LoginForm;

import { useEffect } from "react";
/**
 *  @Component props.data:
 * img
 * imgClass
 * title
 * content
 * buttonText
 * buttonLink
 * border
 *
 */
var parse = require("html-react-parser");
const EmbedIframe = (props) => {
  if (props.data) {
    var { data } = props;
  }
  console.log("iframe", data[0].iframe);
  const iFrameForm = data[0].iframe;

  return (
    <>
      {data[0].title ? <h2>{data[0].title}</h2> : null}
      {data[0].description ? <p>{data[0].description}</p> : null}
      <div>{parse(iFrameForm)}</div>
      {/* {parse(
        `<div class="embedsocial-hashtag" data-ref="7868f8d50d762b9442282acac17739930cefe42f"><a class="feed-powered-by-es feed-powered-by-es-feed-new" href="https://embedsocial.com/social-media-aggregator/" target="_blank" title="Widget by EmbedSocial">Widget by EmbedSocial<span>→</span></a></div><script>(function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialHashtagScript"));</script>`
      )} */}
    </>
  );
};

export default EmbedIframe;

import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navigation from "./Common/navigation";

const BlogCategories = (props) => {
  if (props?.data) {
    var navigations = [...props.data.navigations];
  }
  var categories = [
    ...Object.assign(
      [],
      useSelector((state) => state.blog.blogs.categories)
    ),
  ];
  categories = categories.map((cat) => {
    cat = Object.assign([], cat);
    cat.name = cat.title;
    cat.url = `/blogs/${cat.id}`;
    return cat;
  });
  navigations.map((nav) => {
    nav.items = categories;
    return nav;
  });
 

  return (
    <div className="py-5">
      <Accordion className="blog-accordion">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="title">Blog Categories</div>
          </Accordion.Header>
          <Accordion.Body className="d-flex flex-column">
            {categories?.map((cat, idx) => (
              <Link to={`/blogs/${cat.id}`} key={idx} className="py-2">
                <img style={{ width: "8rem" }} className="pe-2" src={cat.img} />
                {cat.title}
              </Link>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="blog-list">
        <Navigation data={navigations} />
      </div>
    </div>
  );
};
export default BlogCategories;

import axios from "axios";
import config from "../config";
import { accountActions } from "./my-account-slice";
export const getAccountData = (username) => {
  return (dispatch) => {
    const myaccountURL = `/json/${config.project}/response/user.json`;
    axios
      .get(myaccountURL)
      .then((res) => {
        const data = { ...res.data };
        data.username = username;
        dispatch(accountActions.replaceAcountInfo(data));
      })
      .catch((err) => console.log(err));
  };
};
export const login = () => {
  return (dispatch) => {
    dispatch(accountActions.login());
  };
};
export const logout = () => {
  return (dispatch) => {
    dispatch(accountActions.logout());
  };
};

import axios from "axios";
import { Fragment, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Config from "../../config";
/**
 *
 * @Components props.data
 * formGroups[] -> array of {groupname, fields[]}
 *
 * groupname: gives a title to group of fields mentioned in fields object
 * fields[]: array of {label, colclass, type, details},
 *            for groupname "Buttons" it will be array of {buttonText, colclass,color, link},
 *            for type: "Select" and "Radio", it will accept options (which is an array) instead of details
 *            type: "Radio" , also accepts a field "optionClass" to display options in col format
 *
 * label: field Label
 * colclass: css to show how many columns the field will take
 * type: to identify the type of field can have values : Select, Radio, Control, TextArea, State(for dropdown of US states), Review, Info
 * details: its an object {type (for control structures - like email, text, date etc), placeholder, text (a little note below the field)}
 */

const FormContainer = (props) => {
  const [states, setStates] = useState([]);
  const statesAPIurl = `/json/${Config.project}/states.json`;
  if (props) {
    var data = props.data;
    var formGroups = props.data.formGroups;
  }
  return (
    <>
      {data.title ? <h2 className="center">{data.title}</h2> : null}
      <Form className="row px-3 component form-container">
        {formGroups
          .filter(
            ({ groupname, fields }) =>
              !groupname || (groupname && groupname !== "Buttons")
          )
          .map(({ groupname, fields }, idx) => (
            <Fragment key={idx}>
              {groupname && (
                <div className="form_label text-center mt-4 mb-2">
                  {groupname}
                </div>
              )}
              {fields.map((field, index) => {
                switch (field.type) {
                  case "Select":
                    return (
                      <Form.Group
                        className={`pb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label className="text-center">
                          {field.label}
                        </Form.Label>
                        <Form.Select
                          className={
                            field.colclass && field.colclass === "col-md-12"
                              ? "w-50"
                              : ""
                          }
                        >
                          {field.options.map((opt, i) => (
                            <option key={i}>{opt}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    );
                  case "State":
                    axios.get(statesAPIurl).then((res) => {
                      setStates(res.data);
                    });
                    return (
                      <Form.Group
                        className={`pb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Select
                          className={
                            field.colclass && field.colclass === "col-md-12"
                              ? "w-50"
                              : ""
                          }
                        >
                          {states.map(({ name, abbreviation }, i) => (
                            <option value={abbreviation} key={i}>
                              {name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    );
                  case "Radio":
                    return (
                      <Form.Group
                        className={`pb-3 row ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field.label}</Form.Label>
                        {field.options.map((opt, idx) => (
                          <Form.Check
                            type="radio"
                            key={idx}
                            id={`opt-${idx}`}
                            label={opt}
                            className={`${
                              field?.optionClass ? field.optionClass : ""
                            } mx-3`}
                          />
                        ))}
                      </Form.Group>
                    );
                  case "Check":
                    return (
                      <Form.Check
                        type="checkbox"
                        id={field?.id}
                        label={field?.label}
                        className={`mb-3 mx-2 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      />
                    );
                  case "Control":
                    return (
                      <Form.Group
                        className={`mb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field?.label}</Form.Label>
                        {field?.details && (
                          <>
                            <Form.Control
                              type={field?.details?.type}
                              placeholder={field?.details?.placeholder}
                            />
                            <Form.Text className="text-muted">
                              {field?.details?.text}
                            </Form.Text>
                          </>
                        )}
                      </Form.Group>
                    );
                  case "TextArea":
                    return (
                      <Form.Group
                        className={`mb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field?.label}</Form.Label>
                        {field?.details && (
                          <>
                            <Form.Control
                              type={field?.details?.type}
                              placeholder={field?.details?.placeholder}
                              style={{ height: "100px" }}
                            />
                            <Form.Text className="text-muted">
                              {field?.details?.text}
                            </Form.Text>
                          </>
                        )}
                      </Form.Group>
                    );
                  case "Review":
                    return (
                      field?.details && (
                        <div className="my-3" key={index}>
                          {Object.entries(field.details).map(
                            ([key, value], idx) => {
                              return (
                                <span
                                  key={idx}
                                  className="d-block text-capitalize"
                                >
                                  <b>{key}</b> : {value}
                                </span>
                              );
                            }
                          )}
                        </div>
                      )
                    );
                  case "Info":
                    return (
                      field?.details && (
                        <span className="fw-bold my-3" key={index}>
                          {field?.details}
                        </span>
                      )
                    );
                  default:
                    break;
                }
              })}
            </Fragment>
          ))}
        <div className="row pb-3">
          {formGroups
            .filter(({ groupname }) => groupname && groupname === "Buttons")
            .map(({ fields }, idx) =>
              fields?.map((btn, idx) => (
                <div
                  key={idx}
                  className={`${btn?.colclass ? btn.colclass : ""}`}
                >
                  <Link to={btn?.link}>
                    <Button
                      variant={btn?.color}
                      // onClick={props.clickFx}
                      className="w-100"
                    >
                      {btn?.buttonText}
                    </Button>
                  </Link>
                </div>
              ))
            )}
        </div>
      </Form>
    </>
  );
};
export default FormContainer;

import { useState } from "react";
import { Button, Form } from "react-bootstrap";
/**
 *
 * @Container {*} props.data{
 *  title:
 *  details: object {housePrice, iterestRate, years, downPayment}
 * }
 *
 */

const MortgageCalc = (props) => {
  if (props.data) {
    var { title, details } = props.data;
  }
  const [mortgageData, setMortgageData] = useState({
    ...details,
  });
  const calculateMortgage = (e) => {
    e.preventDefault();
    if (mortgageData) {
      const { housePrice, interestRate, years, downPayment } = mortgageData;
      const principal = housePrice - downPayment;
      const months = years * 12;

      const monthlyPay = parseFloat(
        (principal *
          ((interestRate / 1200) * Math.pow(1 + interestRate / 1200, months))) /
          (Math.pow(1 + interestRate / 1200, months) - 1)
      ).toFixed(2);
      setMortgageData({
        ...mortgageData,
        monthlyPay: monthlyPay,
        principal: principal,
        months: months,
      });
    }
  };
  return (
    <div className="card border-0 shadow p-3 mb-3">
      {title && (
        <div className="px-2 pb-3">
          <span className="title">{title}</span>
        </div>
      )}
      <div className="mx-3">
        <Form onSubmit={calculateMortgage}>
          <Form.Group className="mb-1" controlId="formCalcPrice">
            <Form.Label>House Price</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.housePrice}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  housePrice: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-1" controlId="formCalcInterest">
            <Form.Label>Interest Rate</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.interestRate}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  interestRate: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-1" controlId="formCalcYears">
            <Form.Label>Years</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.years}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  years: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-1" controlId="formCalcDownpay">
            <Form.Label>Down Payment</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.downPayment}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  downPayment: e.target.value,
                })
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <div className="py-2 border-top mt-3">
          <div>Results:</div>
          <Form.Group className="mb-1" controlId="principal">
            <Form.Label>Mortgage Principal</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.principal}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-1" controlId="months">
            <Form.Label>Total Payments</Form.Label>
            <Form.Control type="number" value={mortgageData?.months} disabled />
          </Form.Group>
          <Form.Group className="mb-1" controlId="monthlyPay">
            <Form.Label>Monthly Payments</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.monthlyPay}
              disabled
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};
export default MortgageCalc;

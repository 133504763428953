import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
/**
 *
 * @Components props.data
 *  title,
 * activities[]: array of {date, activity}, defines the account activity at a date
 * buttonText,
 * buttonLink
 */

const ActivityCard = (props) => {
  if (props?.data) {
    var { title, activities, buttonText, buttonLink } = props.data;
  }
  return (
    <div className="card border-0 shadow p-3 mb-3 activity-card">
      {title && (
        <div className="px-2 pb-3">
          <span className="title">{title}</span>
        </div>
      )}
      <div className="mx-3">
        {activities?.map(({ date, activity }, idx) => (
          <div className="py-2 border-top row" key={idx}>
            <div className="col-3 font-weight-bold">{date}</div>
            <div className="col-9">{activity}</div>
          </div>
        ))}
      </div>
      {buttonText && (
        <Link to={buttonLink ? buttonLink : "/"}>
          <Button variant="light" size="lg">
            {buttonText}
          </Button>
        </Link>
      )}
    </div>
  );
};
export default ActivityCard;

import { createSlice } from "@reduxjs/toolkit";

const myAccountSlice = createSlice({
  name: "myAccount",
  initialState: {
    accountInfo: {},
    isLoggedIn: false,
  },
  reducers: {
    replaceAcountInfo(state, action) {
      state.accountInfo = action.payload;
    },
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
  },
});

export const accountActions = myAccountSlice.actions;
export default myAccountSlice.reducer;

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
// import Modal from "react-responsive-modal";

// @description :
//  Renders items in Panel2
//  @PROPS:
//  component=[]
//
//  @author: BOBAN
//

// { JSON MODEL IN HERE}

export class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      name: this.props.name,
      phone: this.props.phone,
      email: this.props.email,
      isLoading: true,
      showModal: false,
      activeModal: "",
      settings:[],
      projectName: JSON.parse(window.localStorage.getItem("projectName")),
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  setProject(name) {
    let project = name.toLowerCase();
    //clear up local storage and reload to pickup new project name
    window.localStorage.clear();
    window.localStorage.setItem("projectName", JSON.stringify(project));
    window.location.reload();
  }

  handleCheckboxChange(event) {
    var checkboxes = document.getElementsByName("check");
    checkboxes.forEach((item) => {
      if (item.getAttribute('id') !== event.target.id) item.checked = false;
    });
    // let newArray = [...this.state.settings, event.target.id];
    // if (this.state.settings.includes(event.target.id)) {
    //   newArray = [newArray.filter((tag) => tag !== event.target.id)];
    // }
    // this.setState({ settings: newArray });
   
    if (event.target.id === "LightTheme") { 
    // "colorPrimaryBckg": "#151520",
    // "colorPrimaryText": "#faeffa",
    // "colorSecondaryBckg": "#b852bb",
    // "colorSecondaryText": "#151520",
    // "backgroundColorGrey": "#4e4388",
    // "colorGreyMedium": "#4e4388",
      document.documentElement.style.setProperty(
        "--primary-color-dark",
        "#faeffa"
      );
      document.documentElement.style.setProperty(
        "--primary-color-light",
        "#151520"
      );
      document.documentElement.style.setProperty(
        "--secondary-color-dark",
        "#151520"
      );
      document.documentElement.style.setProperty(
        "--secondary-color-light","#000000"
      );
      document.documentElement.style.setProperty(
        "--text-color:","#000000"
      );
      document.documentElement.style.setProperty(
        "--text-color-light",
        "#b852bb"
      );
       document.documentElement.style.setProperty(
         "--background-image",
         "url('/assets/fengshui-background-light.png')"
       );  
      document.documentElement.style.setProperty("--color-white", "#151520");
      
    }
        if (event.target.id === "DarkTheme") {
          // "colorPrimaryBckg": "#151520",
          // "colorPrimaryText": "#faeffa",
          // "colorSecondaryBckg": "#b852bb",
          // "colorSecondaryText": "#151520",
          // "backgroundColorGrey": "#4e4388",
          // "colorGreyMedium": "#4e4388",
            // --text-color: #3c3c3c;
            // --text-bold-color: #000000;
            // --text-color-light: #ffffff;
            // --text-color-pale: #808080;
          document.documentElement.style.setProperty(
            "--primary-color-dark",
            "#151520"
          );
          document.documentElement.style.setProperty(
            "--primary-color-light",
            "#faeffa"
          );
          document.documentElement.style.setProperty(
            "--secondary-color-dark",
            "#b852bb"
          );
          document.documentElement.style.setProperty(
            "--secondary-color-light",
            "#151520"
          );
          document.documentElement.style.setProperty(
            "--text-color:",
            "#3c3c3c"
          );
          document.documentElement.style.setProperty(
            "--text-color-light",
            "#ffffff"
          );
          document.documentElement.style.setProperty(
            "--background-image",
            "url('/assets/fengshui-background.png')"
          );
          document.documentElement.style.setProperty(
            "--color-white",
            "#ffffff"
          );
        }
  }

  componentDidMount() {
    //adding switch for config property projectName in case if is not set yet

    if (!this.state.projectName) {
      console.log("Project name is not set will refer to default...");
      let project = "fengshui";
      window.localStorage.clear();
      window.localStorage.setItem("projectName", JSON.stringify(project));
      window.location.reload();
    }
    //open modal for Switching demo on Key event
    const self = this;
    document.onkeyup = function (e) {
      if (e.ctrlKey && e.shiftKey && e.which == 32) {
        self.handleOpenModal("switch");
      }
    };
    
  }

  render() {
    const { data} = this.props;
    //console.log("SETTINGS:" ,this.state.settings);
    Modal.setAppElement("#root");
    return (
      <div className="switch-contaier">
        <Modal isOpen={this.state.showModal} contentLabel="login Modal">
          <div className="content">
            <button className="close btn" onClick={this.handleCloseModal}>
              <i className="fa-solid fa-xmark"></i>
            </button>
            <h4 className="center">
              <span>Settings</span>
            </h4>

            <Form.Group as={Row} className="mb-3 checks">
              <Col sm={{ span: 11, offset: 1 }} className="text-small">
                <h2>Browser Storage Preferences</h2>
                <p>
                  We dont use the cookies but when you visit websites, we store
                  some functional data in your browser which do not contain any
                  personal infomration. This storage is necessary for the basic
                  functionality of the website. The storage may be used for
                  personalization of the site, such as storing your preferences
                  related to color theme. Privacy is important to us, so we do
                  not collect private information we use storage for necessary
                  for and basic functioning of the website. Blocking storage may
                  impact your experience on the website so we decided not to
                  collect any user data.
                </p>

                <p>
                  These items help the website operator understand how its
                  website performs, how visitors interact with the site, and
                  whether there may be technical issues. This storage type
                  doesn’t collect information that identifies a visitor.
                </p>
              </Col>

              <Col sm={{ span: 10, offset: 1 }}>
                <Form.Check
                  type="checkbox"
                  label="No tracking Cookies"
                  value="No tracking Cookies"
                  onChange={(e) => this.handleCheckboxChange(e)}
                  checked
                  disabled
                  id="cookies1"
                  className="form-check-inline"
                />
                <Form.Check
                  type="checkbox"
                  label="No functional Cookies "
                  id="cookies2"
                  checked
                  disabled
                  value="No functoinal Cookies"
                  onChange={(e) => this.handleCheckboxChange(e)}
                  className="form-check-inline"
                />
              </Col>
            </Form.Group>

            <Col sm={{ span: 10, offset: 1 }} className="mb-3 text-small">
              <h2>Color Scheme - Theme selection</h2>
              <p>
                These items help the website operator understand how its website
                performs, how visitors interact with the site, and whether there
                may be technical issues. This storage type doesn’t collect
                information that identifies a visitor.
              </p>
              <Form.Check
                type="checkbox"
                label="Dark Theme"
                value="dark"
                onChange={(e) => this.handleCheckboxChange(e)}
                id="DarkTheme"
                className="form-check-inline"
              />
              <Form.Check
                type="checkbox"
                label="Light Theme"
                value="light"
                onChange={(e) => this.handleCheckboxChange(e)}
                id="LightTheme"
                className="form-check-inline"
              />
              <Form.Check
                type="checkbox"
                label="Color Blind Theme"
                value="ColorBlindTheme"
                onChange={(e) => this.handleCheckboxChange(e)}
                id="ColorBlind"
                className="form-check-inline"
              />
              <Form.Check
                type="checkbox"
                label="Minimal Color"
                value="MinimalColor"
                onChange={(e) => this.handleCheckboxChange(e)}
                id="MinimalColor"
                className="form-check-inline"
              />
            </Col>

            <Form.Group as={Row} className="mb-5 demo">
              <Col sm={{ span: 5, offset: 1 }}>
                <button
                  className="btn btn-secondary mb-4"
                  onClick={() => this.handleCloseModal()}
                >
                  Cancel Switch request
                </button>
              </Col>
              {/* <Col sm={{ span: 5, offset: 1 }}>
                <button
                  className="btn  btn-primary mb-4"
                  onClick={() => this.setProject("fengshui")}
                >
                  Switch to : DoFengshui
                </button>
              </Col> */}
              <Col sm={{ span: 5, offset: 1 }}>
                <button
                  className="btn  btn-primary mb-4"
                  onClick={() => this.handleCloseModal()}
                >
                  OK
                </button>
              </Col>
            </Form.Group>
          </div>
        </Modal>
        {/* {this.state.projectName ? (
          <div className="debug">
            Selected <span className="red">website</span>
            {this.state.projectName.name}
          </div>
        ) : (
          <div className="debug">FAILED</div>
        )} */}
      </div>
    );
  }
}

export default Switch;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

var parse = require("html-react-parser");
const CatalogItemCard = (props) => {
  const [prod, setProd] = useState({});
  const products = useSelector((state) => state.product.products);
  if (props.data) {
    var { id } = props.data;
  }
  useEffect(() => {
    setProd(products.find((p) => p.id === id));
  }, [products.size, products, prod, id]);
  if (prod)
    return (
      <Link
        className={`img__wrap ${props?.className ? props.className : ""}`}
        to={`/product/${id}`}
      >
        {prod.img && (
          <img className="img__img center w-100" src={prod.img} alt="" />
        )}
        <div className="img__description_layer">
          <div className="img__description text-center">
            {prod.name && <p className="fw-bold ">{prod.name}</p>}
            {!prod.isSale && prod.price && <p>${prod.price}</p>}
            {prod.isSale && prod.price && prod.salePrice && (
              <>
                <s>${prod.price}</s>
                <span className="font-secondarycolor">
                  {" "}
                  now ${prod.salePrice}
                </span>
              </>
            )}
          </div>
        </div>
        {/* {isSale && <span className="triangle">Sale</span>} */}
      </Link>
    );
};

export default CatalogItemCard;

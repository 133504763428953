import { Row } from "react-bootstrap";
import CatalogItemCard from "../CatalogItemCard";
import PromotionList from "../PromotionList";
import SingleCard from "./card.single";

const componentMap = {
  simpleCard: SingleCard,
  catalogCard: CatalogItemCard,
  promotionList: PromotionList,
};
const defaultCardType = "simpleCard";
const defaultNumberOfCols = 3;
const ContentComponent = (props) => {

  function* chunks(array, n) {
    for (let i = 0; i < array.length; i += n) yield array.slice(i, i + n);
  }
  return (
    props.data &&
    props.data.map((cardSet, index) => {
      if (cardSet) {
        const { items, numberOfCols } = cardSet;
        if (items?.length > 0) {
          var chunkedArray = [
            ...chunks(items, numberOfCols ? numberOfCols : defaultNumberOfCols),
          ];
        }
      }
      return (
        <Row key={index} className="mb-4 mt-3 component card-container">
          {cardSet.title && <h2>{cardSet.title}</h2>}
          {cardSet.componentContent && (
            <div className="mt-2 mb-2 text-center">
              {cardSet.componentContent}
            </div>
          )}
          {chunkedArray &&
            chunkedArray?.map((data, idx) => (
              <Row key={idx}>
                {[
                  ...Array(
                    cardSet.numberOfCols
                      ? cardSet.numberOfCols
                      : defaultNumberOfCols
                  ),
                ].map((item, i) => {
                  const Comp =
                    componentMap[
                      data[i]?.itemType ? data[i].itemType : defaultCardType
                    ];
                  return (
                    <div className="col-sm-12 col-lg" key={i}>
                      {data[i] && <Comp data={data[i]} />}
                    </div>
                  );
                })}
              </Row>
            ))}
        </Row>
      );
    })
  );
};
export default ContentComponent;

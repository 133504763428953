/*
@Component props "links" array with  :
    style,
    title
    items{
      name,
      url
    }  
    
*/

const ContactCard = (props) => {
  const { data } = props;
  return (
    <div className="row py-5 pb-5 component links-component contact-card">
      {data.title && <h2>{data.title}</h2>}
      {data.items.map((link, idx) =>
        link.type === "phone" ? (
          <a
            key={idx}
            className={`col ${data?.style ? data.style : ""}`}
            href={`tel:${link.description}`}
          >
            {link.name && (
              <span>
                <span className={link.name} />
                {link.description && <span>{link.description}</span>}
              </span>
            )}
          </a>
        ) : (
          <a
            key={idx}
            className={`col ${data?.style ? data.style : ""}`}
            href={`mailto:${link.description}`}
          >
            {link.name && (
              <span>
                <span className={link.name} />
                {link.description && <span>{link.description}</span>}
              </span>
            )}
          </a>
        )
      )}
    </div>
  );
};
export default ContactCard;

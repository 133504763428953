import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BlogListCard from "./BlogListCard";
import SingleCard from "./Common/card.single"; 

const BlogArticle = (props) => {
  const categories = useSelector((state) => state.blog.blogs.categories);
  const articles = useSelector((state) => state.blog.blogs.articles);
  const params = useParams();

  if (params) {
    var { catId, artId } = params;
  }
  const findCategoryAndArticles = (catId, artId) => {
    if (artId == 0) var category = categories?.find(({ id }) => id == catId);
    else
      var category = {
        ...articles?.find(
          ({ category, id }) => category == catId && id == artId
        ),
      };
    var filteredArticles = [
      ...articles?.filter(
        (art) => art.category == catId && art.parent == artId
      ),
    ];
    if (filteredArticles?.length == 0) {
      filteredArticles = [
        ...articles?.filter(
          (art) =>
            art.category == catId &&
            art.parent == category.parent &&
            art.id != category.id
        ),
      ];
     
    }

    var articlesForCatId = [
      ...filteredArticles?.map((art) => {
        art = Object.assign([], art);
        art.cardLink = `/blogs/${art?.category}/${art?.id}`;
        art.category = categories?.find((cat) => cat.id == art.category);
        art.content = art.teaser;
        return art;
      }),
    ];
     console.log("BLOG", filteredArticles, category);
    return { category, articlesForCatId };
  };
  if (categories?.length > 0 && articles?.length > 0) {
    if (catId && !artId) {
      var { category, articlesForCatId } = findCategoryAndArticles(catId, 0);
    }
    if (catId && artId) {
      var { category, articlesForCatId } = findCategoryAndArticles(
        catId,
        artId
      );
      category.imgClass = "blog-card-img";
    }
    //no blog cat selected so get first one 
     if (!catId && !artId) {
       var { category, articlesForCatId } = findCategoryAndArticles(1, 0);
     }
  }

  return (
    <div className="blog-article component">
      {category && <SingleCard data={category} />}
     
      {/* {articlesForCatId && <CardContainer data={articlesForCatId} />} */}
      <div>
        {articlesForCatId?.map((art, idx) => {
          return <BlogListCard key={idx} data={art} />;
        })}
      </div>
    </div>
  );
};

export default BlogArticle;

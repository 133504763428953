import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Routers from "./Routers";
import Config from "./config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-responsive-modal/styles.css";
import Switch from "./switch/switch.demo";

function App() {
  const [sitedata, setSiteData] = useState({});
  const [projectNameStatic, setprojectNameStatic] = useState(
    JSON.parse(window.localStorage.getItem("projectName"))
  );
  const [pages, setPages] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
   
    const fetchJson = async () => {
      const res = await axios.get(Config.default);
      if (res.data) {
        setSiteData(res.data.site);
        setPages(res.data.pages);
        setHeaderData(res.data.header);
        setFooterData(res.data.footer);
      }
    };
    // setProject();
    fetchJson();
  }, []);
  if (sitedata) {
    document.documentElement.style.setProperty(
      "--primary-color-dark",
      sitedata.colorPrimaryBckg
    );
    document.documentElement.style.setProperty(
      "--primary-color-light",
      sitedata.colorPrimaryText
    );
    document.documentElement.style.setProperty(
      "--secondary-color-dark",
      sitedata.colorSecondaryBckg
    );
    document.documentElement.style.setProperty(
      "--secondary-color-light",
      sitedata.colorSecondaryText
    );
    document.documentElement.style.setProperty(
      "--background-color-grey",
      sitedata.backgroundColorGrey
    );
    document.documentElement.style.setProperty(
      "--color-grey-medium",
      sitedata.colorGreyMedium
    );
    document.title = sitedata.siteTitle;
  }
  return (
    <main>
      <BrowserRouter>
        {headerData && <Header data={headerData} logo={sitedata.logo} />}

        <Routers pages={pages} />
        {footerData && <Footer data={footerData} site={sitedata} />}
      </BrowserRouter>
      <ToastContainer />
      <Switch />
    </main>
  );
}

export default App;

import axios from "axios";
import config from "../config";
import { cartActions } from "./cart-slice";
export const addAnItem = (item) => {
  return (dispatch) => {
    dispatch(cartActions.addAnItem(item));
  };
};
export const removeAnItem = (id) => {
  return (dispatch) => {
    dispatch(cartActions.removeAnItem(id));
  };
};
export const removeItem = (id) => {
  return (dispatch) => {
    dispatch(cartActions.removeItem(id));
  };
};
export const clearCart = () => {
  return (dispatch) => {
    dispatch(cartActions.clearCart());
  };
};

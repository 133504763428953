import { Fragment } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../login/auth";
/**
 *  @Component props.data:
 * img
 * imgClass
 * title
 * content
 * buttonText
 * buttonLink
 * border
 *
 */
const Navigation = (props) => {
  // if (props.data) {
  //   var { items, type, className } = props.data;
  // }
  const auth = Auth();
  const getClassName = (pos) => {
    if (pos === "right" || pos === "left") return "navigation-col";
    else if (pos === "top" || pos === "bottom") return "navigation-row";
    else return "";
  };
  const getNav = (navData, idx) => {
    const nav = (
      <Nav
        className={`${
          navData?.className ? navData.className : ""
        } ${getClassName(navData?.position)}`}
        as="ul"
      >
        {navData?.items &&
          navData?.items?.map((item, index) => (
            <Nav.Item className="my-1" key={index} as="li">
              {(!item.restricted || (item?.restricted && auth)) && (
                <Link to={item?.url ? item.url : "/"} className="px-2 py-0">
                  {item?.name && <span>{item.name}</span>}
                </Link>
              )}
            </Nav.Item>
          ))}
      </Nav>
    );
    if (navData?.type && navData?.type === "collapse")
      return (
        <>
          <Navbar.Toggle
            className="col-md-1 offset-md-5 col-2 offset-4"
            aria-controls={`basic-navbar-nav-${idx}`}
          />
          <Navbar.Collapse
            className="col-lg-6 col-md-5"
            id={`basic-navbar-nav-${idx}`}
          >
            {nav}
          </Navbar.Collapse>
        </>
      );
    // else if (navData.type && navData.type === "offcanvas")
    //   return (
    //     <Navbar expand={false} className="mb-3">
    //       <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false" />
    //       <Navbar.Offcanvas
    //         id="offcanvasNavbar-expand-false"
    //         aria-labelledby={`offcanvasNavbarLabel-expand-false`}
    //         placement="end"
    //       >
    //         <Offcanvas.Header closeButton>Nav</Offcanvas.Header>
    //         <Offcanvas.Body>{nav}</Offcanvas.Body>
    //       </Navbar.Offcanvas>
    //     </Navbar>
    //   );
    else return nav;
  };
  return (
    <>
      {props?.data &&
        props?.data?.map((navData, idx) => {
          return <Fragment key={idx}>{getNav(navData, idx)}</Fragment>;
        })}
    </>
  );
};

export default Navigation;

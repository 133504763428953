import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
/**
 *  @Component props.data:
 * title (string)
 * content (string)
 * component(array)
 */
class SimpleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const onOpenModal = () => {
      this.setState({ open: true });
    };

    const onCloseModal = () => {
      this.setState({ open: false });
    };
    const { data, componentsMap, site } = this.props;
    return (
      <>
        <button onClick={onOpenModal}>{data.triggerTitle}</button>
        <Modal open={this.state.open} onClose={onCloseModal}>
          <h2>{data.title}</h2>
          <div className="content">
            {data.component
              ? data.component.map((item, idx) =>
                  Object.entries(item).map(function ([key, val]) {
                    const Comp = componentsMap[key];
                    return (
                      <Comp
                        data={val}
                        site={site}
                        key={idx}
                        clickFx={onCloseModal}
                      />
                    );
                  })
                )
              : data.content}
          </div>
        </Modal>
      </>
    );
  }
}

export default SimpleModal;

import axios from "axios";
import config from "../config";
import { blogActions } from "./blogs-slice";
export const getBlogs = () => {
  return (dispatch) => {
    const myaccountURL = `/json/${config.project}/response/blogs.json`;
    axios
      .get(myaccountURL)
      .then((res) => {
        dispatch(blogActions.replaceBlogs(res.data));
      })
      .catch((err) => console.log(err));
  };
};

import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
  },
  reducers: {
    replaceProducts(state, action) {
      state.products = action.payload;
    },
    addToWishlist(state, action) {
      const prodIdx = state.products.findIndex(
        (p) => p.id == action.payload.id
      );
      state.products[prodIdx].inWishlist = true;
    },
    removeFromWishlist(state, action) {
      const prodIdx = state.products.findIndex(
        (p) => p.id == action.payload.id
      );
      state.products[prodIdx].inWishlist = false;
    },
  },
});

export const productActions = productsSlice.actions;
export default productsSlice.reducer;

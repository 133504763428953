import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Navigation from "../Common/navigation";
import User from "../login/userData";
import Auth from "../login/auth";
import { useDispatch, useSelector } from "react-redux";
import { Badge, NavDropdown } from "react-bootstrap";
import { logout } from "../../store/my-account-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import { toast } from "react-toastify";
/**
 *
 * @Container props
 * logo: Website Logo image
 * data.items[]: array of {url, name} -> for navigation links
 */
const Header = (props) => {
  const auth = Auth();
  const user = User();
  const dispatch = useDispatch();

  const totalItems = useSelector((state) => state.cart.totalItems);
  const logoutClick = (e) => {
    toast.success("User logged out successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(logout());
  };
  const welcomeUserDropdown = (
    <>
      <span className="font-white">
        Welcome {user?.username} <FontAwesomeIcon icon={faUser} />
      </span>
    </>
  );
  return (
    <Navbar
      expand="lg"
      className="header py-0 px-3"
     
    >
      <div className="row w-100 align-items-center">
        <Navbar.Brand className=" col-lg-3 col-6 p-0 m-0 ">
          <Link to="/home">
            {props?.logo && (
              <img
                src={props.logo}
                alt=""
                className={
                  props?.data?.logoClassname ? props.data.logoClassname : ""
                }
              />
            )}
          </Link>
        </Navbar.Brand>

        {props?.data && props.data.navigations && (
          <Navigation data={props.data.navigations} />
        )}
        {config?.project === "finance" && (
          <>
            {!auth && (
              <Link
                to="/login"
                className="col-lg-2 offset-lg-1 col-md-2 offset-md-10 col-2 offset-10 font-white py-2"
              >
                <span className="font-white text-uppercase login-header">
                  Login
                </span>
              </Link>
            )}
            {auth && user && (
              <div className="col-lg-2 offset-lg-1 col-md-2 offset-md-10 col-4 offset-8 py-2">
                <NavDropdown title={welcomeUserDropdown} menuVariant="light">
                  <NavDropdown.Item onClick={logoutClick}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            )}
          </>
        )}

        {/* {auth && user && (
          <div className="col-lg-2 offset-lg-1 col-md-2 offset-md-10 col-4 offset-8">
            <NavDropdown title={welcomeUserDropdown} menuVariant="light">
              <NavDropdown.Item onClick={logoutClick}>Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        )} */}
        {config.project === "retail" && (
          <div className="col-lg-3  col-md-12 row py-2">
            {!auth && (
              <Link to="/login" className="col-2 px-2 font-white">
                <span className="font-white text-uppercase">Login</span>
              </Link>
            )}
            {auth && user && (
              <div className="col-2">
                <NavDropdown title={welcomeUserDropdown} menuVariant="light">
                  <NavDropdown.Item onClick={logoutClick}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            )}
            <Link to="/cart" className="col-1 offset-9 px-2 font-white">
              <span className="font-white text-uppercase position-absolute">
                {totalItems > 0 && (
                  <Badge bg="secondary cart-badge-header">{totalItems}</Badge>
                )}
                <i className="fa-solid fa-cart-shopping fa-lg"></i>
              </span>
            </Link>
          </div>
        )}
      </div>
    </Navbar>
  );
};

export default Header;

import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import HomePage from "./templates/default";

const Routers = (props) => {
  const location = useLocation();

  return (
    <Routes>
      <Route
        path="/"
        element={<HomePage jsonFile="/home.json" />}
        exact
      ></Route>
      <Route
        path="/social"
        element={<HomePage jsonFile="/social.json" />}
        exact
      ></Route>
      <Route
        path="product/:id"
        element={<HomePage jsonFile="/product-detail.json" />}
      />

      <Route path="blogs/:catId" element={<HomePage jsonFile="/blog.json" />} />
      <Route
        path="blogs/:catId/:artId"
        element={<HomePage jsonFile="/blog.json" />}
      />
      {props.pages &&
        props.pages.map((page, idx) => (
          // ((page.restricted && auth) || !page.restricted) &&
          <Route
            key={idx}
            path={`/${page.pagename}`}
            element={
              <HomePage
                restricted={page.restricted}
                jsonFile={page.jsonLocation}
              />
            }
          />
        ))}
      {props?.pages?.length > 0 &&
        props.pages.filter((page) =>
          location.pathname.includes("/" + page.pagename)
        ).length == 0 && <Route path="*" element={<Navigate to="/" />} />}

      {/* {props?.pages?.length > 0 &&
        (props.pages.filter(
          (page) =>
            location.pathname.includes(page.pagename) && !page.restricted
        ).length == 0 ||
          (props.pages.filter(
            (page) =>
              location.pathname.includes(page.pagename) && page.restricted
          ).length > 0 &&
            auth)) && <Route path="*" element={<Navigate to="/" />} />} */}
    </Routes>
  );
};
export default Routers;

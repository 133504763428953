import { Badge, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeAnItem, addAnItem, removeItem } from "../store/cart-actions";
import { useEffect, useState } from "react";

const Cart = (props) => {
  const items = useSelector((state) => state.cart.items);
  const total = useSelector((state) => state.cart.total);

  const dispatch = useDispatch();
  const addAnItemToCart = (item) => {
    dispatch(addAnItem(item));
  };
  const removeAnItemFromCart = (id) => {
    dispatch(removeAnItem(id));
  };
  const removeItemFromCart = (id) => {
    dispatch(removeItem(id));
  };
  return (
    <>
      {!props.data.inProductPage && (
        <>
          {items && items.length === 0 && (
            <div className="py-5 mt-3 mb-5 container text-center">
              <div className="fw-bold font-1_4 pb-3">Your Cart</div>
              <div className="pb-3">Your Cart is empty</div>
              <div>
                Continue Browsing{" "}
                <Link className="font-black" to="/catalog">
                  here
                </Link>
              </div>
            </div>
          )}
          {items && items.length > 0 && (
            <>
              <Table className="cart-table my-5">
                <thead>
                  <tr>
                    <th colSpan={2}>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.map((item, idx) => {
                      return (
                        <tr className="cart-row" key={idx}>
                          <td className="text-center cart-item-image">
                            <Link to={`/product/${item.id}`}>
                              <img src={item.img} alt={item.name} />
                            </Link>
                          </td>
                          <td className="cart-item-name">
                            <div className="fw-bold">{item.name}</div>
                            <Link
                              className="d-flex font-black font-0_7"
                              onClick={removeItemFromCart.bind(null, item.id)}
                            >
                              Remove
                            </Link>
                          </td>
                          <td className=" cart-item-price" data-label="Price: ">
                            {item && !item.isSale && item.price && (
                              <p>${item.price}</p>
                            )}
                            {item &&
                              item.isSale &&
                              item.price &&
                              item.salePrice && (
                                <>
                                  <s>${item.price}</s>
                                  <span className="font-secondarycolor">
                                    {" "}
                                    now ${item.salePrice}
                                  </span>
                                </>
                              )}
                          </td>
                          <td
                            className=" cart-item-quantity"
                            data-label="Quantity: "
                          >
                            <div className="input-group quantity-input">
                              <input
                                type="button"
                                value="-"
                                className="button-minus"
                                data-field="quantity"
                                onClick={removeAnItemFromCart.bind(
                                  null,
                                  item.id
                                )}
                              />
                              <input
                                type="number"
                                step="1"
                                max=""
                                value={item.quantity}
                                onChange={() => {}}
                                name="quantity"
                                className="quantity-field"
                              />
                              <input
                                type="button"
                                value="+"
                                className="button-plus"
                                data-field="quantity"
                                onClick={addAnItemToCart.bind(null, item)}
                              />
                            </div>
                          </td>
                          <td
                            className=" cart-item-amount"
                            data-label="Amount: "
                          >
                            ${item.amount}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <div className="pb-5 d-flex justify-content-center flex-column align-items-center">
                <div className="d-flex">
                  <div className="fw-bold pe-2">Subtotal : </div>${total} USD
                </div>
                <div className="d-flex font-0_7">
                  Taxes and shipping calculated at checkout
                </div>
                <Link to="/checkout">
                  <Button className="mt-3">CheckOut</Button>
                </Link>
              </div>
            </>
          )}
        </>
      )}
      {props.data.inProductPage && items.length > 0 && (
        <div className="cart-products-page">
          <h4 className="pt-3">Your Cart</h4>
          <div className="d-flex py-3">
            <div className="fw-bold pe-2">Subtotal : </div>
            <div className="font-secondarycolor fw-bold">${total} USD</div>
          </div>
          {items?.map((itm, idx) => (
            <div key={idx}>
              <div>
                <img src={itm.img} width="100" />
              </div>
              <Badge className="bg-primary cart-item-badge">
                {itm.quantity}
              </Badge>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default Cart;

import { ProgressBar } from "react-bootstrap";
import FormContainer from "./Common/formContainer";

/**
 * @Components props.data
 * title,
 * progress: progress bar percent
 * formGroups[]: form related info
 */
const ApplicationProcess = (props) => {
  if (props?.data) {
    var { title, progress, formGroups } = props.data;
  }
  return (
    <div className="card border-0 shadow mb-3">
      {title && (
        <div className="card-header bg-grey">
          <div className="form_label py-2">{title}</div>
          {progress && <ProgressBar now={progress} label={`${progress}%`} />}
        </div>
      )}
      <div className="card-body">
        <FormContainer data={formGroups} />
      </div>
    </div>
  );
};

export default ApplicationProcess;

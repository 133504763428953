/*
@Component props "links" array with  :
    style,
    title
    items{
      name,
      url
    }  
    
*/

import { Link } from "react-router-dom";

const LinksContainer = (props) => {
  const { data } = props;
  return (
    <div className="row py-5 pb-5 component links-component">
      {data.title && <h2>{data.title}</h2>}
      {data.items.map((link, idx) => (
        <Link
          key={idx}
          to={link.url ? link.url : "/"}
          className={`col ${data?.style ? data.style : ""}`}
        >
          {link.name && <span>{link.name}</span>}
          {link.description && <i>{link.description}</i>}
        </Link>
      ))}
    </div>
  );
};
export default LinksContainer;

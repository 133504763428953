import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
/**
 *  @Component props.data:
 * img
 * imgClass
 * title
 * content
 * buttonText
 * buttonLink
 * border
 *
 */
var parse = require("html-react-parser");
const SingleCard = (props) => {
  if (props.data) {
    var {
      img,
      imgClass,
      title,
      content,
      buttonText,
      buttonLink,
      border,
      target,
    } = props.data;
  }

  var borderstyle = border ? (border === "all?" ? "border" : "") : "";

  return (
    <Card
      className={`${borderstyle} ${
        props?.className ? props.className : ""
      } text-center my-2 pt-2 border-0`}
    >
      {img && (
        <Card.Img
          variant="top"
          src={img}
          className={imgClass ? imgClass : ""}
        />
      )}

      {title ||
        (content && (
          <Card.Body>
            {title && <Card.Title>{title}</Card.Title>}
            {content && <Card.Text>{parse(content)}</Card.Text>}
            {buttonText && (
              <Link to={buttonLink}>
                <Button variant="primary" target={target} className="my-2">
                  {parse(buttonText)}
                </Button>
              </Link>
            )}
          </Card.Body>
        ))}
    </Card>
  );
};

export default SingleCard;

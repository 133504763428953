import { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Auth from "./login/auth";
import { addAnItem } from "../store/cart-actions";
import { toast } from "react-toastify";

const ProductDetails = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const params = useParams();
  const dispatch = useDispatch();
  const [prod, setProd] = useState({});
  const products = useSelector((state) => state.product.products);
  if (params) {
    var { id } = params;
  }
  useEffect(() => {
    setProd(products.find((p) => p.id === id));
  }, [products.size, products, prod, id]);
  const auth = Auth();
  const addToCart = (e) => {
    toast(prod.name + " added to cart.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(addAnItem(prod));
  };
  return (
    <>
      <div>
        <div className="row">
          {prod && prod.img && (
            <img className="col-md-6 col-12" src={prod.img} alt="" />
          )}
          <div className="col-md-6 col-12 d-flex flex-column align-items-start">
            {prod && prod.name && (
              <span className="fw-bold fs-4 mt-5 mb-3">{prod.name}</span>
            )}
            {prod && prod.description && <p>{prod.description}</p>}
            {prod && !prod.isSale && prod.price && (
              <p className="fw-bold">${prod.price}</p>
            )}
            {prod && prod.isSale && prod.price && prod.salePrice && (
              <>
                <s>${prod.price}</s>
                <span className="font-secondarycolor">
                  {" "}
                  now ${prod.salePrice}
                </span>
              </>
            )}
            {auth && (
              <Button variant="dark" className=" my-2">
                <i className="fa-regular fa-heart"></i> Add to Wishlist
              </Button>
            )}
            {!auth && (
              <span>
                Please <Link to="/login">login</Link> to add items to wishlist
              </span>
            )}
            <Button className=" my-2" onClick={addToCart}>
              {" "}
              Add to Cart
            </Button>
            <Button variant="dark" className="my-2">
              Buy it now
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetails;

import { Link } from "react-router-dom";

const BlogListCard = (props) => {
  if (props?.data) {
    var { title, img, content, category, cardLink } = props.data;
  }
  const temp = (
    <>
      {img && <img src={img} className="card-img-top" alt="..." />}
      <div className="card-body">
        {category?.title && (
          <div className="card-category">{category?.title}</div>
        )}
        {title && <h5 className="card-title">{title}</h5>}
        {content && <p className="card-text">{content}</p>}
      </div>
    </>
  );
  if (!cardLink) {
    return <div className="blog-list-card card bg-grey my-2 px-2">{temp}</div>;
  }
  return (
    <Link className="blog-list-card card bg-grey my-2 px-2" to={cardLink}>
      {temp}
    </Link>
  );
};

export default BlogListCard;
